import dayjs from 'dayjs';
import {
  COPIES,
  DATE,
  DATE_FULL,
  DATE_FULL_TIME,
  DAY,
  TIME,
  TIME_FULL,
  DATE_FULL_MONTH_LONG_TIME_FULL,
  DATE_FULL_TIME_FULL,
  DATE_TIME_FULL,
  DATE_MIDDLE_MONTH_LONG_WITH_DOTES,
} from '@/shared/constants';
import { RangeValue } from '@/shared/types';

export class TimeService {
  private isSameDate(timestamp: number) {
    return dayjs().isSame(timestamp, 'day');
  }

  private isSameYear(timestamp: number) {
    return dayjs().isSame(timestamp, 'year');
  }

  getFormattedDate(timestamp: number, withFullTime?: boolean) {
    const isSameDate = this.isSameDate(timestamp);
    const isSameYear = this.isSameYear(timestamp);
    const dateFormat = isSameYear ? DATE : DATE_FULL;
    const timeFormat = withFullTime ? TIME_FULL : TIME;

    const format = isSameDate ? timeFormat : dateFormat;

    return dayjs(timestamp).format(format);
  }

  getFormattedFullTimeOrDate(timestamp: number) {
    const isSameDate = this.isSameDate(timestamp);
    const isSameYear = this.isSameYear(timestamp);

    if (isSameDate) return dayjs(timestamp).format(TIME_FULL);

    if (isSameYear) return dayjs(timestamp).format(DATE_TIME_FULL);

    return dayjs(timestamp).format(DATE_FULL_TIME_FULL);
  }

  getFormattedFullTimeMonthLong(timestamp: number) {
    return dayjs(timestamp).format(DATE_FULL_MONTH_LONG_TIME_FULL);
  }

  getFormattedMiddleMonthWithDotes(date: string | number) {
    return dayjs(date).format(DATE_MIDDLE_MONTH_LONG_WITH_DOTES);
  }

  getDateRange(startDate?: number | null, endDate?: number | null) {
    if (!startDate && !endDate) return COPIES.NO_DATA;
    if (!endDate) return COPIES.NO_ACTIVITY;

    const dateFirst = dayjs(startDate);
    const dateSecond = dayjs(endDate);

    if (dayjs(startDate).isSame(endDate, 'day')) {
      return dateSecond.format(DATE_FULL);
    }

    if (dayjs(startDate).isSame(endDate, 'month')) {
      return `${dateFirst.format(DAY)} – ${dateSecond.format(DATE_FULL)}`;
    }

    if (dayjs(startDate).isSame(endDate, 'year')) {
      return `${dateFirst.format(DATE)} – ${dateSecond.format(DATE_FULL)}`;
    }

    return `${dateFirst.format(DATE_FULL)} – ${dateSecond.format(DATE_FULL)}`;
  }

  getDateFullTimeRange(startDate?: number | null, endDate?: number | null) {
    if (!startDate || !endDate) return;

    if (dayjs(startDate).isSame(endDate, 'day')) {
      return dayjs(endDate).format(DATE_FULL_TIME);
    }

    return `${dayjs(startDate).format(DATE_FULL_TIME)} – ${dayjs(endDate).format(DATE_FULL_TIME)}`;
  }

  convertRangeValueToTimestamps(value: RangeValue): [number, number] | null {
    return value?.[0] && value?.[1] ? [value[0].valueOf(), value[1].valueOf()] : null;
  }

  convertTimestampsToRangeValue<T>(value: [T, T] | T[] | null) {
    return value && value.length > 0 ? (value.map(Number).map(dayjs) as RangeValue) : null;
  }
}

export const timeService = new TimeService();
