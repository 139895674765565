import { z } from 'zod';
import { RadioNetworkType } from '../constants';

const radioNetworkTypeSchema = z.nativeEnum(RadioNetworkType);

// get list endpoints still use these responses (V2 api)
const radioNetworkItemSchemaV2 = z.object({
  id: z.string(),
  value: z.string(),
  type: radioNetworkTypeSchema,
  first_active_at: z.number(),
  last_active_at: z.number(),
  is_locked: z.boolean().optional(),
});

export const frequencySchemaV2 = radioNetworkItemSchemaV2.merge(
  z.object({
    type: z.literal('frequency'),
    source_name: z.string().optional(),
  })
);

export const networkSchemaV2 = radioNetworkItemSchemaV2.merge(
  z.object({
    type: z.literal('network'),
    description: z.string().nullish(),
    frequencies: z.array(frequencySchemaV2),
  })
);

export const radioNetworkSchemaV2 = z.discriminatedUnion('type', [frequencySchemaV2, networkSchemaV2]);

// (V3 api)
export const radioNetworkItemSchema = z.object({
  id: z.string(),
  value: z.string(),
  type: radioNetworkTypeSchema,
  firstActiveAt: z.number(),
  lastActiveAt: z.number(),
  isLocked: z.boolean().optional(),
});

export const frequencySchema = radioNetworkItemSchema.merge(
  z.object({
    type: z.literal('frequency'),
    sourceName: z.string().optional(),
  })
);

export const networkSchema = radioNetworkItemSchema.merge(
  z.object({
    type: z.literal('network'),
    description: z.string().nullish(),
    frequencies: z.array(frequencySchema),
  })
);

export const radioNetworkSchema = z.discriminatedUnion('type', [frequencySchema, networkSchema]);
