import pick from 'lodash/pick';
import { RadioNetworkType } from '@/shared/constants';
import { RadioNetwork, RadioNetworkV2 } from '@/shared/types';

const NORMALIZED_FIELDS: (keyof RadioNetworkV2)[] = ['id', 'value'];

const adaptV2ToV3RadioNetwork = <T = RadioNetwork>(radioNetwork: RadioNetwork | RadioNetworkV2): T => {
  if ('firstActiveAt' in radioNetwork) return radioNetwork as T;

  const commonProps = {
    ...pick(radioNetwork, NORMALIZED_FIELDS),
    firstActiveAt: radioNetwork.first_active_at,
    lastActiveAt: radioNetwork.last_active_at,
    isLocked: radioNetwork.is_locked,
  };

  return (
    radioNetwork.type === RadioNetworkType.FREQUENCY
      ? {
          ...commonProps,
          type: RadioNetworkType.FREQUENCY,
          sourceName: radioNetwork.source_name,
        }
      : {
          ...commonProps,
          type: RadioNetworkType.NETWORK,
          description: radioNetwork.description,
          frequencies: radioNetwork.frequencies.map((frequency) => ({
            ...pick(frequency, NORMALIZED_FIELDS),
            firstActiveAt: frequency.first_active_at,
            lastActiveAt: frequency.last_active_at,
            isLocked: frequency.is_locked,
            type: RadioNetworkType.FREQUENCY,
            sourceName: frequency.source_name,
          })),
        }
  ) as T;
};

export default adaptV2ToV3RadioNetwork;
